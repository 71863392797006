import React, { useState, useContext, useEffect } from "react";
import { LOGIN } from "../../mutations";
import { useMutation } from "@apollo/client";

import { navigate, Link } from "gatsby";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { CredentialsContext } from "../../context/CredentialsContext";
import client from "../../apollo/client";
import { AuthUserContext } from "../../context/AuthUser";

import eyeIcon from "../../images/icons/eye.icon.svg";
import eyeCloseIcon from "../../images/icons/eye-close.icon.svg";
import { getCurrentUser } from "../../services/api";

const Login = () => {
  const { setAuthUser } = useContext(AuthUserContext);

  const { credentials, setCredentials } = useContext(CredentialsContext);

  const [showIcon, setShowIcon] = useState(false);
  const [messageError, setMessageError] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("user_data");

    if (token) {
      navigate(userData ? "/orders" : "/home");
    } else {
      client.clearStore();
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
      navigate("/login");
    }
  }, [navigate, client]);

  const formValidate = (errors, ...data) => {
    const hasErrors = Object.values(errors).some((val) => val.length > 0);
    const hasEmptyFields = data.some((val) => val === "");

    return !hasErrors && !hasEmptyFields;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageError((prevErrors) => ({
      ...prevErrors,
      [name]: value === "" ? "This field is required" : "",
    }));

    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * Mutation for login auth with strapi graphql
   */
  const [login, { loading: mutationLoading, error: mutationError }] =
    useMutation(LOGIN, {
      onCompleted: async ({ login: { jwt, user } }) => {
        if (!jwt) return;

        localStorage.setItem("token", jwt);

        try {
          const response = await getCurrentUser(jwt);
          const { role, typecustomer } = response;

          if (role?.id) {
            navigate(role.id === 4 ? "/orders" : "/home");
            setAuthUser({
              logged: true,
              typeCustomer: typecustomer,
              role: role.id,
              user: { ...user, confirmed: typecustomer },
            });
            toast.success("Successful login");
          } else {
            toast.error("The user does not have access");
          }
        } catch (error) {
          toast.error("Failed to fetch user details");
        }
      },
      onError: () => {
        setAuthUser({ logged: false, user: {} });
        toast.error("Invalid username or password");
        navigate("/login");
      },
      refetchQueries: ["refreshCurrentUser"],
    });

  const handleLogin = () => {
    const { username, password } = credentials;

    if (username && password) {
      login({
        variables: { login: username, password },
      });
    } else {
      toast.error("Please enter username and password");
    }
  };

  /**
   *  Method for identifier user with jwt
   * @param {*Only prevent the reload page} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.values(messageError).some((error) => error)) {
      return toast.error("Please, enter username and password");
    }

    if (formValidate(messageError, credentials)) {
      handleLogin();
    } else {
      toast.error("Fill in the required fields");
    }
  };

  /**
   * Handle => Change icon and type for input password
   */
  const handleChangeType = () => {
    const element = document.getElementById("changeType");
    const isTextType = element.type === "text";

    element.type = isTextType ? "password" : "text";
    setShowIcon(!isTextType);
  };

  return (
    <>
      {mutationLoading && <Loading />}
      {mutationLoading && (
        <div className="message-loading">
          <div className="loading loading-card"></div>
          <p>Please wait a moment to prepare your textile library.</p>
        </div>
      )}
      <section className="container container-card-login">
        <h2>Login</h2>
        <div className="container-card-login__content">
          <form onSubmit={handleSubmit} noValidate>
            <div className="container-card-login__input">
              <input
                type="text"
                name="username"
                placeholder="E-mail address"
                onChange={handleChange}
                onBlur={handleChange}
                className={`u-width-100 ${
                  messageError.username ? "border-error" : ""
                } `}
              />
              {messageError.username && (
                <small className="field-error">{messageError.username}</small>
              )}
            </div>

            <div className="container-card-login__input u-flex-column-smartphone">
              <div className="u-position-relative content-input-password">
                <input
                  type="password"
                  name="password"
                  id="changeType"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className={`u-width-100 ${
                    messageError.password ? "border-error" : ""
                  } `}
                />
                <span onClick={handleChangeType}>
                  {showIcon ? (
                    <img className="icon-password" src={eyeCloseIcon} alt="" />
                  ) : (
                    <img className="icon-password" src={eyeIcon} alt="" />
                  )}
                </span>
                {messageError.password && (
                  <small className="field-error">{messageError.password}</small>
                )}
              </div>
              <button
                type="submit"
                disabled={mutationLoading ? true : false}
                className="btn btn-primary button-log-in"
              >
                {mutationLoading ? <div className="loading"></div> : "Login"}
              </button>
            </div>

            <p>
              Please agree to the <Link to="/terms-and-conditions">terms</Link>{" "}
              of service and proceed.
            </p>
            <div className="content-buttons-login">
              <Link to="/forgot-information" className="forgot">
                Forgot login information?
              </Link>
            </div>
          </form>
          <div className="content-buttons-login">
            <Link to="/register-new" className="forgot">
              Sign up now
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
