import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Container } from "../components/styled/ProductsStyled";

import { ORDER_ID } from "../querys/index";
import { getImageFromDrive } from "../services/api";
import { UPDATE_ORDER } from "../mutations";
import { toast } from "react-toastify";
import { apiBase } from "../services/appenv";
import axios from "axios";
import { navigate } from "gatsby";

import noImage from "../images/icons/no-image.svg";

const InputField = ({ label, name, value, onChange, placeholder }) => (
  <div className="input-order-field">
    <label className="" htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className=""
    />
  </div>
);

const DetailProducts = ({ products, onChange }) => (
  <>
    {products.length === 0 ? (
      ""
    ) : (
      <h4 className="font-semibold mt-4" style={{ marginBottom: "0.5em" }}>
        Detail Products
      </h4>
    )}

    {products.map((product, index) => (
      <div key={index} className="">
        {/* <h4 className="font-semibold">Product {index + 1}</h4> */}
        <div className="u-display-flex u-flex-wrap">
          {product.image ? (
            <img
              src={getImageFromDrive(product.image)}
              alt="product Preview"
              className="thumbnail-order"
            />
          ) : (
            ""
          )}

          <div
            className="u-display-flex u-flex-wrap u-margin-left-1"
            style={{ width: "calc(100% - 100px)" }}
          >
            <InputField
              label="Article Number"
              name={`detailProducts.${index}.articleNumber`}
              value={product.articleNumber}
              onChange={onChange}
              placeholder="Article Number"
            />
            <InputField
              label="Color Number"
              name={`detailProducts.${index}.colorNumber`}
              value={product.colorNumber}
              onChange={onChange}
              placeholder="Color Number"
            />
            <InputField
              label="Quantity"
              name={`detailProducts.${index}.quantity`}
              value={product.quantity}
              onChange={onChange}
              placeholder="Quantity"
            />
            <InputField
              label="Total Price"
              name={`detailProducts.${index}.totalPrice`}
              value={product.totalPrice}
              onChange={onChange}
              placeholder="Total Price"
            />
            <InputField
              label="Regular Price"
              name={`detailProducts.${index}.regularPrice`}
              value={product.regularPrice}
              onChange={onChange}
              placeholder="Regular Price"
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

const DetailSwatches = ({ swatches, onChange }) => (
  <>
    {swatches.length === 0 ? (
      ""
    ) : (
      <h4 className="font-semibold mt-4" style={{ marginBottom: "0.5em" }}>
        Detail Swatch
      </h4>
    )}

    {swatches.map((swatch, index) => (
      <div key={index} className="orders-row-products">
        {/* <h4 className="font-semibold">Swatch {index + 1}</h4> */}
        <div className="u-display-flex u-flex-wrap">
          {swatch.image ? (
            <img
              src={getImageFromDrive(swatch.image)}
              alt="Swatch Preview"
              className="thumbnail-order"
            />
          ) : (
            ""
          )}

          <div
            className="u-display-flex u-flex-wrap u-margin-left-1"
            style={{ width: "calc(100% - 100px)" }}
          >
            <InputField
              label="Swatch Article Number"
              name={`detailSwatch.${index}.articleNumber`}
              value={swatch.articleNumber}
              onChange={onChange}
              placeholder="Swatch Article Number"
            />
            <InputField
              label="Swatch Color Number"
              name={`detailSwatch.${index}.colorNumber`}
              value={swatch.colorNumber}
              onChange={onChange}
              placeholder="Swatch Color Number"
            />
            <InputField
              label="Swatch Quantity"
              name={`detailSwatch.${index}.quantity`}
              value={swatch.quantity}
              onChange={onChange}
              placeholder="Swatch Quantity"
            />
            <InputField
              label="Swatch Total Price"
              name={`detailSwatch.${index}.totalPrice`}
              value={swatch.totalPrice}
              onChange={onChange}
              placeholder="Swatch Total Price"
            />
            <InputField
              label="Swatch Regular Price"
              name={`detailSwatch.${index}.regularPrice`}
              value={swatch.regularPrice}
              onChange={onChange}
              placeholder="Swatch Regular Price"
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

const OrderHistoryInfoDetail = ({ id }) => {
  const [orderID, setOrderID] = useState({});

  const { loading, error } = useQuery(ORDER_ID, {
    variables: { orderhistoryId: id },
    onCompleted: (data) => {
      setOrderID(data);
    },
  });

  const token = localStorage.getItem("token");
  const HEADERS = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const [updateOrderhistory] = useMutation(UPDATE_ORDER);
  const initializeFormData = (data) => ({
    amount: data.amount || "",
    email: data.email || "",
    username: data.username || "",
    shippingFee: data.shippingFee || "",
    companyName: data.companyName || "",
    dateOrder: data.dateOrder || "",
    payment_method: data.payment_method || "",
    shippingAddress: {
      address: data.shippingAddress?.address || "",
      city: data.shippingAddress?.city || "",
      shippingUser: data.shippingAddress?.shippingUser || "",
      phoneNumber: data.shippingAddress?.phoneNumber || "",
      zipcode: data.shippingAddress?.zipcode || "",
      street: data.shippingAddress?.street || "",
    },
    detailProducts: Array.isArray(data.detailProducts)
      ? data.detailProducts.map((product) => ({
          articleNumber: product.articleNumber || "",
          colorNumber: product.colorNumber || "",
          quantity: product.quantity || "",
          totalPrice: product.totalPrice || "",
          regularPrice: product.regularPrice || "",
          image: product.image || "",
        }))
      : [],
    detailSwatch: Array.isArray(data.detailSwatch)
      ? data.detailSwatch.map((swatch) => ({
          articleNumber: swatch.articleNumber || "",
          colorNumber: swatch.colorNumber || "",
          quantity: swatch.quantity || "",
          totalPrice: swatch.totalPrice || null,
          regularPrice: swatch.regularPrice || null,
          image: swatch.image || "",
        }))
      : [],
    status: data.status || "",
  });

  const [formData, setFormData] = useState(
    initializeFormData(orderID?.orderhistory?.data.attributes || {})
  );

  useEffect(() => {
    const newData = orderID?.orderhistory?.data.attributes || {};
    setFormData(initializeFormData(newData));
  }, [orderID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    setFormData((prevData) => {
      const newData = { ...prevData };
      let nested = newData;

      keys.forEach((key, idx) => {
        if (!isNaN(key)) {
          const arrayIndex = parseInt(key, 10);

          if (idx === keys.length - 1) {
            nested[arrayIndex] = value;
          } else {
            nested[arrayIndex] = nested[arrayIndex] || {};
            nested = nested[arrayIndex];
          }
        } else {
          if (idx === keys.length - 1) {
            nested[key] = value;
          } else {
            nested[key] = nested[key] || {};
            nested = nested[key];
          }
        }
      });

      return newData;
    });
  };
  const handleEmailOrderStatus = () => {
    axios
      .post(
        `${apiBase}/api/mail-order-history`,
        JSON.stringify(orderData),
        HEADERS
      )
      .then((response) => {
        toast.success(`Email send successfully!`);
        // console.log(response, "response");
        navigate("/orders-history");
      })
      .catch((error) => {
        toast.error(`Error, please try again`);
      })
      .finally(() => {});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFields = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== orderID[key]) {
        updatedFields[key] = formData[key];
      }
    });

    try {
      await updateOrderhistory({
        variables: {
          id: id,
          data: updatedFields,
        },
      });
      handleEmailOrderStatus();
      toast.success("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };
  const [orderDescription, setOrderDescription] = useState("");

  const orderData = {
    status: formData.status,
    user: formData.user,
    email: formData.email,
    description: orderDescription,
    order: id,
  };

  if (loading) return <p></p>;
  if (error) return <p>Error loading order data</p>;

  return (
    <Container className="container">
      <div className="row">
        <h2
          className="font-semibold mt-4 u-margin-bottom-1"
          style={{ color: "#555555" }}
        >
          Order Detail #{id}
        </h2>
        <ul style={{ marginLeft: "2px" }}>
          {/* <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Company Name: </b>{" "}
            {formData.companyName}
          </li> */}
          {/* <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Amount: </b>
            {formData.amount}
          </li> */}
          <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Order Date: </b>{" "}
            {new Date(formData.dateOrder).toLocaleDateString()}{" "}
            {new Date(formData.dateOrder).toLocaleTimeString()}
          </li>
          {/* <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Payment Method: </b>
            {formData.payment_method}
          </li> */}
          <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Status: </b>
            <p
              style={{
                display: "inline",
                padding: "2px 8px",
                borderRadius: "5px",
                background:
                  formData.status === "Ordered"
                    ? "#F5B041"
                    : formData.status === "Done"
                    ? "#58D68D"
                    : "#5DADE2",
              }}
            >
              {formData.status}
            </p>
          </li>
        </ul>

        {formData?.detailProducts.length !== 0 ? (
          <div className="content-scroll-h">
            <h2 className="title-length-order">Length Order</h2>
            <table className="col-lg-12 col-md-12 table-order-content">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Article Number</th>
                  <th>Color Number</th>
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </tr>
              </thead>
              <tbody>
                {formData?.detailProducts.map((product, i) => (
                  <tr key={i}>
                    <td>
                      {product.image ? (
                        <img
                          src={getImageFromDrive(product.image)}
                          alt={product.articleNumber}
                          className="thumbnail-order"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{product.articleNumber}</td>
                    <td>{product.colorNumber.replaceAll('"', "")}</td>
                    <td>{product.quantity}</td>
                    {/* <td>${product?.totalPrice?.toFixed(2)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
        {formData?.detailSwatch !== 0 ? (
          <div className="content-scroll-h">
            <h2 className="title-length-order">Swatch Order</h2>
            <table className="col-lg-12 col-md-12 table-order-content">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Article Number</th>
                  <th>Color Number</th>
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </tr>
              </thead>
              <tbody>
                {formData?.detailSwatch.map((product, i) => (
                  <tr key={i}>
                    <td>
                      {product.image ? (
                        <img
                          src={getImageFromDrive(product.image)}
                          alt={product.articleNumber}
                          className="thumbnail-order"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noImage;
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{product.articleNumber}</td>
                    <td>{product.colorNumber.replaceAll('"', "")}</td>
                    <td>{product.quantity}</td>
                    {/* <td>${product?.totalPrice?.toFixed(2)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}

        {/* <DetailProducts
          products={formData?.detailProducts}
          onChange={handleChange}
        />

        <DetailSwatches
          swatches={formData?.detailSwatch}
          onChange={handleChange}
        /> */}
      </div>
    </Container>
  );
};
export default OrderHistoryInfoDetail;
