import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import HeartLike from "../cards/Style/HeartLike";
import Loader from "../../components/Loader";
import {
  Select,
  List,
  Quantity,
  ListDetail,
  TotalPrice,
  InputQuantity,
} from "./Style/DetailProductStyle";
import {
  CURRENT_USER,
  GET_FAVORITE,
  GET_FAVORITE_ITEMS,
  PRODUCT_BY_ID,
} from "../../querys";
import useProducts from "../../hooks/useProducts";
import { useQuery, useMutation } from "@apollo/client";
import Magnifier from "react-magnifier";

import { toast } from "react-toastify";
import { navigate } from "gatsby";
import {
  ADD_FAVORITE,
  CREATE_SHIPPING_CART,
  DELETE_FAVORITE,
  GET_CART_ITEM,
  GET_SHIPPING_CART,
  UPDATE_FAVORITE,
  UPDATE_SHIPPING_CART,
} from "../../mutations";
import { SearchContext } from "../../context/SearchContext";
import { CartContentContext } from "../../context/CartContentContext";
import useIcons from "../../hooks/useIcons";
import { ButtonTL } from "../styled/Button";
import {
  getBigImageFromDrive,
  getImageFromDrive,
  getVideoFromDrive,
} from "../../services/api";
import Modal from "react-modal";
import { customStylesMagnifier } from "../../services/modal";

import iconBack from "../../images/icons/back.icon.svg";
import iconGrid from "../../images/icons/grid.icon.svg";
import iconSlideShow from "../../images/icons/slideshow.icon.svg";
import noDataFound from "../../images/icons/no-data.icon.svg";
import noImage from "../../images/icons/no-image.svg";
import closeImg from "../../images/icons/close.icon.svg";
import { AuthUserContext } from "../../context/AuthUser";
import SmallLoader from "../small-loader";
import { typeUserManagment } from "../../services/querysData";

const DetailProduct = ({ id, location, current_user }) => {
  const { authUser } = useContext(AuthUserContext);
  const { confirmed } = current_user;
  const allImages = useIcons();
  const { cartContent, setCartContent, swatchContent, setSwatchContent } =
    useContext(CartContentContext);

  // console.log(dataProduct, 'dataProductdataProduct');
  const dataGRAPH = useProducts();
  const dataQUERY = dataGRAPH;

  let dataGRAPHFilter = dataQUERY.filter(
    (post) =>
      post.productId.replace(/[^a-zA-Z0-9-_]/g, "") ==
      id.replace(/[^a-zA-Z0-9-_]/g, "")
  );

  if (dataGRAPHFilter.length === 0) {
    dataGRAPHFilter = [
      {
        articleNumber: "",
        category: 0,
        checked: false,
        collectionTag: "",
        colorNumber_productCategory_: "",
        flagforDelete: 0,
        id: "",
        image: "",
        fabricName: "",
        inventory: 0,
        inventoryUnlimitedFlag: 0,
        productDetail: "",
        productId: "no-data",
        productKinds: 0,
        productSettingDelete: 0,
        productSummery: "",
        publishStatus: 0,
        regularPrice: 0,
        priceB: 0,
        searchWord: "",
        supplierId: 0,
        supplier_sProductName: 0,
        noData: true,
        video: "",
        subImage01: "",
        subImage02: "",
        subImage03: "",
        subImage04: "",
      },
    ];
  }

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const { keySearch, setKeySearch } = useContext(SearchContext);
  const [itemContext, setItemContext] = useState({
    nameKey: keySearch.nameKey,
    tagKey: keySearch.tagKey,
    tagButton: keySearch.tagButton,
  });
  useEffect(() => {
    setKeySearch({
      nameKey: itemContext.nameKey,
      tagKey: itemContext.tagKey,
      tagButton: itemContext.tagButton,
    });
  }, []);

  const inventoryArray = [];
  const itemCart = [];
  const priceArray = [];
  const checkFavorite = [];
  const dataVideo = [];
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [inventoryItem, setInventoryItem] = useState(
    dataGRAPHFilter[0].inventory
  );
  const [itemToCart, setItemToCart] = useState(dataGRAPHFilter[0]);

  let slider1;
  let slider2;

  /**
   * Quanty Total
   */
  const [quatyTotal, setQuatyTotal] = useState(0);
  let [valueInputPrice, setValueInputPrice] = useState(1);
  // const [valueSwatch, setValueSwatch] = useState(0)
  const [stockAvailable, setStockAvailable] = useState(false);

  const [valueQuantity, setvalueQuantity] = useState(0);
  const [valueQuantitySwatch, setValueQuantitySwatch] = useState(1);

  const leesQty = () => {
    if (valueQuantity === 1) {
      setvalueQuantity(1);
    } else {
      setvalueQuantity(parseInt(valueQuantity) - 1);
    }
    if (valueQuantity) {
      setQuatyTotal(
        parseFloat(plpProduct[0]?.attributes.regularprice * valueQuantity)
      );
    } else {
      setQuatyTotal(parseFloat(priceb * valueQuantity));
    }
  };

  const addMoreQty = () => {
    if (valueQuantity === 0) {
      setvalueQuantity(1);
      setQuatyTotal(parseFloat(plpProduct[0]?.attributes.regularprice));
    } else {
      let aux = parseInt(valueQuantity + 1);
      setvalueQuantity(aux);
      if (valueQuantity) {
        setQuatyTotal(
          parseFloat(plpProduct[0]?.attributes.regularprice * valueQuantity)
        );
      } else {
        setQuatyTotal(parseFloat(priceb * valueQuantity));
      }
    }
  };
  const changeValuePrice = (event) => {
    setvalueQuantity(event.target.value);
    if (event.target.value < 500) {
      setQuatyTotal(
        parseFloat(plpProduct[0]?.attributes.regularprice * event.target.value)
      );
    } else {
      setQuatyTotal(parseFloat(priceb * event.target.value));
    }
    const ELEMENT_INPUT = document.querySelector(".input-stock");
    if (valueQuantity > parseInt(inventoryItem)) {
      setStockAvailable(true);
      if (inventoryItem !== 0)
        return ELEMENT_INPUT.classList.add("maximum-stock");
    } else {
      setStockAvailable(false);
      if (inventoryItem !== 0)
        return ELEMENT_INPUT.classList.remove("maximum-stock");
    }
    setValueInputPrice(parseInt(event.target.value));
    const valuePrice =
      event.target.value * plpProduct[0]?.attributes.regularprice;
    // setvalueQuantyy(parseInt(event.target.value))
    setQuatyTotal(valuePrice);
    if (isNaN(valueQuantity)) {
      setQuatyTotal(0);
    }
  };

  /**
   *
   * @param event => For validate input only numbers
   */
  const validateInputNumber = (event) => {
    event.target.value = event.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "");
  };
  const [quantySwatch, setQuantySwatch] = useState(1);
  let [valueQuantySwatch, setValueQuantySwatch] = useState(1);

  const leesQtySwatch = () => {
    if (valueQuantitySwatch === 1) {
      valueQuantitySwatch = 1;
    } else {
      setValueQuantitySwatch(valueQuantitySwatch - 1);
    }
  };
  const addMoreQtySwatch = () => {
    if (valueQuantitySwatch < 50) {
      setValueQuantitySwatch(valueQuantitySwatch + 1);
    } else {
      setValueQuantitySwatch(50);
    }
  };
  const changePriceSwatch = (event) => {
    const value = event.target.value.replace(/[^\d\.]/g, "");

    if (isNaN(value)) {
      return setValueQuantitySwatch(0);
    }
    if (value < 1) {
      return setValueQuantitySwatch(1);
    }

    if (parseInt(event.target.value) > 50) {
      toast.warning("The maximum stock is 50");
      return setValueQuantitySwatch(50);
    }

    setValueQuantitySwatch(value);

    setQuantySwatch(value);
  };

  useEffect(() => {
    const ELEMENT_INPUT = document?.querySelector(".input-stock");
    const ELEMENT_INPUT_SWATCH = document.querySelector(".input-stock-swatch");
    if (dataGRAPHFilter[0].noData === true) {
    } else {
      if (setValueInputPrice > inventoryItem) {
      } else {
        setStockAvailable(false);

        if (inventoryItem !== 0)
          return ELEMENT_INPUT.classList.add("maximum-stock");
      }
      const totalVALUE = valueQuantity * plpProduct[0]?.attributes.regularprice;
      const totalSWATCH = valueQuantySwatch * 1;
      if (ELEMENT_INPUT_SWATCH) {
        ELEMENT_INPUT_SWATCH.value = valueQuantySwatch;
      }
      // console.log(ELEMENT_INPUT?.value, inventoryItem, "ELEMENT_INPUT");
      // if (inventoryItem !== 0) return (ELEMENT_INPUT?.value = valueQuantity);
      setQuantySwatch(totalSWATCH);
      setQuatyTotal(totalVALUE);
    }

    const ELEMENT_CLONED = document.querySelectorAll(".slick-cloned");
    for (let i = 0; i < ELEMENT_CLONED.length; i++) {
      if (ELEMENT_CLONED.length <= 1) {
        ELEMENT_CLONED[i].parentNode.parentNode.parentNode.style.display =
          "none";
      } else if (ELEMENT_CLONED.length < 3) {
        ELEMENT_CLONED[i].lastChild.lastChild.style.display = "none";
      }
    }
  }, [valueQuantity, valueQuantySwatch]);

  /**
   *
   * @param data => this data product
   * @param quanty => totalPrice
   */
  const addToCarttest = (data, action) => {
    if (action === "product") {
      const {
        id,
        articleNumber,
        colorNumber_productCategory_,
        image,
        regularPrice,
        priceb,
        inventory,
        productDetail,
      } = data;

      const dataSendCart = {
        id: id,
        image: image,
        articleNumber: articleNumber,
        totalPrice: parseFloat(quatyTotal.toFixed(2)),
        quanty: valueQuantity,
        regularprice: parseFloat(regularPrice).toString(),
        priceB: parseFloat(priceb),
        colornumberproductcategory: colorNumber_productCategory_,
        inventory: inventory,
        productDetail: productDetail,
      };

      const indexOfProduct = cartContent.findIndex(
        (alreadyInCart) => alreadyInCart.id === data.id
      );

      if (indexOfProduct !== -1) {
        if (
          parseInt(cartContent[indexOfProduct].quanty) +
            parseInt(valueQuantity) <=
          inventoryItem
        ) {
          cartContent[indexOfProduct].quanty =
            parseInt(cartContent[indexOfProduct].quanty) +
            parseInt(valueQuantity);
          if (cartContent[indexOfProduct].quanty < 500) {
            cartContent[indexOfProduct].totalPrice =
              cartContent[indexOfProduct].quanty * regularPrice;
          } else {
            cartContent[indexOfProduct].totalPrice =
              cartContent[indexOfProduct].quanty * priceb;
          }
          toast.success(`${valueQuantity} amounts were added to the product.`);
          setvalueQuantity(0);
          setQuatyTotal(0);
          if (cartContent[indexOfProduct].quanty === 0) {
            cartContent.splice(indexOfProduct, 1);
          }
          setCartContent((prevState) => [...prevState]);
        } else {
          toast.error("Quantity in cart is greater than stock");
        }
      } else {
        setCartContent((prevState) => [...prevState, dataSendCart]);
        setValueInputPrice(0);
        setvalueQuantity(0);
        setQuatyTotal(0);

        toast.success("Added to cart");
      }
    } else {
      const {
        id,
        articleNumber,
        colorNumber_productCategory_,
        image,
        regularPrice,
        productDetail,
      } = data;

      const dataSendSwatch = {
        id: id,
        articleNumber: articleNumber,
        colornumberproductcategory: colorNumber_productCategory_,
        image: image,
        quanty: confirmed ? quantySwatch : 1,
        totalPrice: confirmed ? 0 : parseFloat(quantySwatch.toFixed(2)),
        regularprice: confirmed ? 0 : parseFloat(regularPrice),
        productDetail: productDetail,
      };
      const indexOfSwatch = swatchContent.findIndex(
        (alreadyInCart) => alreadyInCart.id === data.id
      );

      if (indexOfSwatch !== -1) {
        if (confirmed) {
          toast.success("Already added! To add more go to  cart.");
        } else {
          toast.success("This swatch is already added. Couldn't add more.");
        }
      } else {
        dataSendSwatch.quanty = 1;

        setSwatchContent((prevState) => [...prevState, dataSendSwatch]);
        toast.success("Added to cart.");
      }

      /*if (confirmed) {
        if (valueQuantitySwatch === 0) {
          toast.error("Quantity must have a value greater than 0")
        } else {
          if (indexOfSwatch !== -1) {
            if (
              parseInt(swatchContent[indexOfSwatch].quanty) +
                parseInt(quantySwatch) <=
              50
            ) {
              swatchContent[indexOfSwatch].quanty =
                parseInt(swatchContent[indexOfSwatch].quanty) +
                parseInt(quantySwatch)
              toast.success(`${quantySwatch} amounts were added to the swatch.`)
              setValueQuantitySwatch(0)
              if (swatchContent[indexOfSwatch].quanty === 0) {
                swatchContent.splice(indexOfSwatch, 1)
              }
              setSwatchContent(prevState => [...prevState])
            } else {
              toast.error("Quantity in cart is greater than stock")
            }
          } else {
            setSwatchContent(prevState => [...prevState, dataSendSwatch])
            setValueQuantitySwatch(0)
            toast.success("Added to cart")
          }
        }
      } else {
        if (indexOfSwatch !== -1) {
          toast.success("This swatch is already added")
        } else {
          dataSendSwatch.quanty = 1

          setSwatchContent(prevState => [...prevState, dataSendSwatch])
          toast.success("Added to cart.")
        }
      }*/
    }
  };

  // useEffect(() => {
  //   localStorage.setItem("cart", JSON.stringify(cartContent))
  //   localStorage.setItem("swatch", JSON.stringify(cartContent))
  // }, [cartContent])

  const {
    data: dataUSER,
    loading: queryLoading,
    error: errorLoading,
  } = useQuery(CURRENT_USER);

  const [likeFavorite, setLikeFavorite] = useState(false);
  const { data: dataFAVORITE } = useQuery(GET_FAVORITE, {
    variables: { id: dataUSER ? dataUSER.me.id : "" },
    onCompleted: (data) => {
      for (let i = 0; i < dataGRAPHFilter.length; i++) {
        const sameFavorite = data.favorites.data.filter(
          (dataFav) =>
            dataFav.attributes.colornumber ===
              itemToCart.colorNumber_productCategory_ &&
            dataFav.attributes.productsid.replace(/[^a-zA-Z0-9-_]/g, "") ===
              itemToCart.productId.replace(/[^a-zA-Z0-9-_]/g, "")
        );
        if (sameFavorite[0]) {
          itemToCart.checked = true;
          setLikeFavorite(true);
        } else {
          itemToCart.checked = false;
          setLikeFavorite(false);
        }
      }
    },
  });
  const handleCheckFavorite = (checked, productID, color) => {
    const favoriteSame = dataFAVORITE.favorites.data.filter(
      (fav) =>
        fav.attributes.colorNumber === color &&
        fav.attributes.productsid.replace(/[^a-zA-Z0-9-_]/g, "") ===
          productID.replace(/[^a-zA-Z0-9-_]/g, "")
    );
    if (checked === true) {
      deleteFavorite({
        variables: { id: parseInt(favoriteSame[0].id) },
        refetchQueries: ["getFavorites"],
      });
      itemToCart.checked = false;
      favoriteSame.splice(1, 1);
    } else {
      addFavorite({
        variables: {
          data: {
            productsid: itemToCart.productId.replace(/[^a-zA-Z0-9-_]/g, ""),
            users_permissions_user: dataUSER.me.id,
            image: itemToCart.image,
            articlenumber: itemToCart.articleNumber,
            productsummery: itemToCart.productSummery,
            colornumber: itemToCart.colorNumber_productCategory_,
            regularprice: itemToCart.regularPrice,
          },
        },
        refetchQueries: ["getFavorites"],
      });
      itemToCart.checked = true;
    }
    forceUpdate();
  };

  const handleInventoryItem = (position) => {
    for (let i = 0; i < dataGRAPHFilter.length; i++) {
      const sameFavorite = dataFAVORITE.favorites.filter(
        (dataFav) =>
          dataFav.colorNumber ===
            dataGRAPHFilter[i].colorNumber_productCategory_ &&
          dataFav.productId.replace(/[^a-zA-Z0-9-_]/g, "") ===
            dataGRAPHFilter[i].productId.replace(/[^a-zA-Z0-9-_]/g, "")
      );
      if (sameFavorite[0]) {
        dataGRAPHFilter[i].checked = true;
      } else {
        dataGRAPHFilter[i].checked = false;
      }
    }

    dataGRAPHFilter.forEach((invent) => {
      const { inventory, regularPrice, checked, video } = invent;
      itemCart.push(invent);
      inventoryArray.push(inventory);
      priceArray.push(regularPrice);
      checkFavorite.push(checked);
      dataVideo.push(video);

      setInventoryItem(inventoryArray[position]);

      const elementSelect = document.getElementById("colorOptions");
      const elementSelect2 = document.getElementById("colorOptionsMobil");

      // elementSelect.options[position].selected = true
      elementSelect2.children[position].selected = true;
      elementSelect.children[position].selected = true;
      // Data for cart
      setItemToCart(itemCart[position]);
      const valuePrice = valueQuantity * priceArray[position];
      const ELEMENT_INPUT = document.querySelector(".input-stock");
      if (valueQuantity > inventoryArray[position]) {
        setStockAvailable(true);
        // ELEMENT_INPUT.classList.add("maximum-stock")
        if (inventoryItem !== 0)
          return ELEMENT_INPUT.classList.add("maximum-stock");
      } else {
        setStockAvailable(false);
        // ELEMENT_INPUT.classList.remove("maximum-stock")
        if (inventoryItem !== 0)
          return ELEMENT_INPUT.classList.remove("maximum-stock");
      }
      setQuatyTotal(valuePrice);
    });
  };

  const handleChangeColor = () => {
    const elementSelect = document.getElementById("colorOptions");
    const valueSelect =
      elementSelect.options[elementSelect.selectedIndex].value;
    slider1.slickGoTo(valueSelect);
  };
  const handleChangeColorMobil = () => {
    const elementSelect = document.getElementById("colorOptionsMobil");
    const valueSelect =
      elementSelect.options[elementSelect.selectedIndex].value;
    slider1.slickGoTo(valueSelect);
  };

  // const [addFavorite] = useMutation(ADD_FAVORITE, {
  //   onCompleted: () => {
  //     toast.success("Added to favorites");
  //   },
  //   refetchQueries: ["getFavorites"],
  // });
  const [deleteFavorite] = useMutation(DELETE_FAVORITE, {
    onCompleted: () => {
      toast.error("Removed from favorites successfully");
    },
  });

  useEffect(() => {
    const elementSelect = document.getElementById("colorOptions");
    const elementSelect2 = document.getElementById("colorOptionsMobil");
    if (location) {
      if (location.state) {
        if (location.state.colorNumber) {
          for (let i = 0; i < elementSelect.options.length; i++) {
            if (
              parseInt(elementSelect.options[i].label) ===
              location.state.colorNumber
            ) {
              handleInventoryItem(elementSelect.options[i].index);
              slider1.slickGoTo(elementSelect.options[i].index);
            }
          }
          for (let k = 0; k < elementSelect2.options.length; k++) {
            if (
              parseInt(elementSelect2.options[k].label) ===
              location.state.colorNumber
            ) {
              handleInventoryItem(elementSelect2.options[k].index);
              slider1.slickGoTo(elementSelect2.options[k].index);
            }
          }
        }
      }
    }
  }, []);
  /**
   * Replece width image 25 to 800
   */
  useEffect(() => {
    const images = document.querySelectorAll("img");
    const imgOptions = {};
    const imgObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        const img = entry.target;
        img.src = img.src.replace("w600&", "w900&");
        imgObserver.unobserve(entry.target);
      });
    }, imgOptions);

    images.forEach((img) => {
      imgObserver.observe(img);
    });
  }, [itemToCart]);

  // const goBack = () => {
  //   e.preventDefault()
  //   history.back()
  // }

  const [slideState, setSlideState] = useState(false);

  const activeGridSlide = () => {
    setSlideState(true);
    const ELEMENT_GRID = document.querySelector(
      ".content-product__multi-image"
    );
    ELEMENT_GRID.classList.add("grid-slide");
    // handleInventoryItem(0)
  };
  const activeLineSlide = () => {
    setSlideState(false);
    const ELEMENT_GRID = document.querySelector(
      ".content-product__multi-image"
    );
    ELEMENT_GRID.classList.remove("grid-slide");
    // handleInventoryItem(0)
  };
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    // window.scrollTo(0, 0)
  }, [slider1, slider2]);

  const dataAttrb = [];
  const [dataIcons, setDataIcons] = useState([]);
  useEffect(() => {
    const detailAttrb = dataGRAPHFilter[0].detailAttribute;

    dataAttrb.push(detailAttrb || "".split(","));

    const di = [];
    let im;
    allImages.allFile.nodes.filter((x) => {
      if (dataAttrb[0].length !== 1) {
        im = dataAttrb[0].split(",");
        im.forEach((y) => {
          if (x.name === y) {
            di.push({ image: x.publicURL, name: y });
            setDataIcons(di);
          }
        });
      }
    });
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const openFullScreen = (modalimage) => {
    setModalImage(modalimage);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  /*
  const [modalOpenMagnifier, setModalOpenMagnifier] = useState(false)
  const [modalMagnifierResponsive, setMagnifierResponsive] = useState("")
  const openModalMagnifier = imgRes => {
    setMagnifierResponsive(imgRes)
    setModalOpenMagnifier(true)
  }
  const closeModalMagnifier = () => {
    setModalOpenMagnifier(false)
  }*/

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 601) {
        closeModal();
      }
    };
    window.addEventListener("resize", handleWindowResize);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [getCartValue, setGetCartValue] = useState([]);
  const [getIdCart, setGetIdCart] = useState("");
  const [getidCartpproduct, setGetidCartpproduct] = useState("");

  const [favoriteItem, setFavoriteItem] = useState({});
  const { data: favoriteList, loading: loadingFavorite } = useQuery(
    GET_FAVORITE_ITEMS,
    {
      variables: {
        id: authUser?.user?.id,
      },
      onCompleted: (data) => {
        setFavoriteItem(data);
      },
    }
  );

  const [addFavorite, { loading: addFavoriteLoading }] = useMutation(
    ADD_FAVORITE,
    {
      onCompleted: () => {
        toast.success("Added to favorites successfully");
      },
      onError: () => {
        toast.error(
          "Failed to add favorite, there was a problem on the server"
        );
      },
    }
  );
  const [updateFavorite, { loading: updateFavoriteLoading }] = useMutation(
    UPDATE_FAVORITE,
    {
      onCompleted: () => {
        // toast.success("Your favorites were updated");
      },
      onError: () => {
        toast.error("Server error, please try again later.");
      },
    }
  );

  useEffect(() => {
    setFavoriteItem(favoriteList);
  }, [favoriteList, addFavorite, updateFavorite]);

  const hadnleAddFavorite = (item) => {
    const {
      productId,
      image,
      articleNumber,
      productSummery,
      colorNumber_productCategory_,
      regularPrice,
    } = item;

    if (
      favoriteItem.favorites.data[0]?.attributes.favoritelist.find(
        (item) => item.productsid === productId
      )
    ) {
      const favoritelist =
        favoriteItem.favorites.data[0]?.attributes.favoritelist
          .filter((item) => item.productsid !== productId)
          .map(({ __typename, ...rest }) => rest);

      updateFavorite({
        variables: {
          id: favoriteItem.favorites.data[0]?.id,
          data: {
            favoritelist,
            users_permissions_user: authUser?.user?.id,
          },
        },
        refetchQueries: ["getFavoriteItem"],
        onCompleted: () => {
          toast.success("Your favorites were updated");
        },
      });
    } else {
      const favoritelist = {
        productsid: productId,
        image: image,
        articlenumber: articleNumber,
        productsummery: productSummery,
        colornumber: colorNumber_productCategory_,
        regularprice: parseFloat(regularPrice),
      };

      if (favoriteItem.favorites.data.length === 0) {
        addFavorite({
          variables: {
            data: {
              favoritelist,
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getFavoriteItem"],
        });
      } else {
        updateFavorite({
          variables: {
            id: favoriteItem.favorites.data[0]?.id,
            data: {
              favoritelist: [
                ...favoriteItem.favorites.data[0]?.attributes.favoritelist.map(
                  ({ __typename, ...rest }) => rest
                ),
                favoritelist,
              ],
            },
          },
          refetchQueries: ["getFavoriteItem"],
          onCompleted: () => {
            toast.success("Your favorites were updated");
          },
        });
      }
    }
  };

  // TODO CHECAR CARRITO DE COMPRAS CUANDO ESTA EN CERO Y NO AGREGAR OTRO CARRITO AL USUARIO

  const { data: dataCartItem } = useQuery(GET_CART_ITEM, {
    variables: { id: getidCartpproduct ? getidCartpproduct : "" },
    onCompleted: (data) => {
      const cartData = [];
      setGetIdCart(data.cartItem.data.id);
      data.cartItem.data.attributes.cartitem.map((item) => {
        cartData.push({
          image: item.image,
          price: item.price,
          productSummery: item.productSummery,
          supplierid: item.supplierid,
          supplierproductname: item.supplierproductname,
          typeofpurchase: item.typeofpurchase,
          productId: item.productId,
          colortype: item.colortype,
          quantity: item.quantity,
          unitprice: item.unitprice,
        });
      });

      setGetCartValue(cartData);
    },
  });

  const [items, setItems] = useState({});
  const { data: cartList, loading: loadingShipping } = useQuery(
    GET_SHIPPING_CART,
    {
      variables: {
        id: authUser?.user?.id,
      },
      onCompleted: (data) => {
        setItems(data);
        // setGetidCartpproduct(data.cartItems.data[0]?.id);
      },
    }
  );

  const [addToCart, { addLoading }] = useMutation(CREATE_SHIPPING_CART, {
    onCompleted: () => toast.success("Added to cart."),
    onError: () => toast.error("Error adding to cart, please try again"),
  });

  const [updateCart, { updateLoading }] = useMutation(UPDATE_SHIPPING_CART, {
    onCompleted: () => {
      toast.success("Cart updated successfully");
    },
    onError: () => {
      // set && true((prev) => ({ ...prev, [item.id]: false }));
      toast.error("There was a problem, the cart could not be updated");
    },
  });

  useEffect(() => {
    setItems(cartList);
  }, [cartList, updateCart, addToCart]);

  const handleAddLengthToCart = async (producto, type) => {
    const {
      productsid,
      colornumber,
      image,
      productsummery,
      regularprice,
      supplierid,
      supplierproductname,
    } = producto.attributes;

    if (
      items &&
      items.cartItems?.data[0]?.attributes?.cartitem?.find(
        (item) => item.productId === productsid
      )
    ) {
      toast.success("The product is already in the cart.");
    } else {
      const cartitem = {
        quantity: "1",
        productId: productsid,
        colortype: colornumber,
        image,
        productSummery: productsummery,
        price: regularprice?.toString(),
        typeofpurchase: type,
        supplierid: supplierid,
        supplierproductname: supplierproductname,
        unitprice: regularprice.toString(),
      };

      if (items.cartItems.data.length === 0) {
        addToCart({
          variables: {
            data: {
              cartitem,
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getCartItems", "getCartItem"],
        });
      } else {
        updateCart({
          variables: {
            id: items?.cartItems?.data[0]?.id,
            data: {
              cartitem: [
                ...items.cartItems.data[0]?.attributes.cartitem.map(
                  ({ __typename, ...rest }) => rest
                ),
                cartitem,
              ],
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getCartItems", "getCartItem"],
        });
      }
    }
  };
  const [plpProduct, setPlpProduct] = useState([]);

  const { loading, error } = useQuery(PRODUCT_BY_ID, {
    variables: {
      product: dataGRAPHFilter[0].productId,
      typeUser: typeUserManagment(authUser?.typeCustomer),
    },
    onCompleted: (data) => {
      setPlpProduct(data?.products?.data);
    },
  });

  if (loading) return <Loader />;
  const {
    articlenumber,
    colornumber,
    fabricname,
    image,
    priceb,
    searchword,
    subimage1,
    subimage2,
    subimage3,
    subimage4,
  } = (plpProduct[0] && plpProduct[0]?.attributes) || {};

  const dataSubImages = {
    subimage1,
    subimage2,
    subimage3,
    subimage4,
  };

  const subimages = Object.values(dataSubImages).map((value) => value);

  return (
    <>
      {plpProduct.length_order === 0 ? (
        <>
          <section className="container container-error-article">
            <img src={noDataFound} alt="No data found" />
            <p>The item cannot be found or does not exist.</p>
          </section>
          <Link to="/products" className="btn btn-primary btn-goback">
            Back to products
          </Link>
        </>
      ) : (
        <>
          {/* {loading && <Loading />} */}
          <div className="container-fluid content-product u-padding-left-right-3">
            <button
              onClick={() => window.history.go(-1)}
              className="content-product__btn-back"
            >
              <img src={iconBack} alt="" /> Back
            </button>
            <div className="product-detail show-mobil">
              <div className="product-detail__title">
                <h3 className="u-text-center">{articlenumber}</h3>
                <h2 className="product-detail__fabric-name">{fabricname}</h2>

                <div className="content-like-heart">
                  <input
                    type="checkbox"
                    id={itemToCart.id}
                    checked={favoriteList?.favorites?.data[0]?.attributes?.favoritelist.some(
                      (fav) => fav.productsid === articlenumber
                    )}
                    onChange={() => hadnleAddFavorite(itemToCart)}
                    // like-check asdasd
                    className="element-checkbox like-check"
                  />
                  <label htmlFor={itemToCart.id}>
                    <HeartLike />
                  </label>
                </div>
              </div>

              {(colornumber?.split(",").length === 1 &&
                colornumber?.split(",")[0] === "0") ||
              !colornumber?.split(",") ? (
                ""
              ) : (
                <div className="product-detail__toolbar-color">
                  <span>Color :</span>
                  <Select
                    name=""
                    id="colorOptionsMobil"
                    onChange={() => handleChangeColorMobil()}
                  >
                    {colornumber?.split(",").map((colors, i) => (
                      <option value={i} key={i}>
                        {colors}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div className="row no-gutters">
              {image === "#N/A" || !image ? (
                // <div className="col-lg-7 no-image-article">
                //   <img src={noImage} />
                //   <p>Without image</p>
                // </div>
                ""
              ) : (
                <div className="col-lg-7">
                  <Slider
                    className="content-product__only-image line-slide"
                    asNavFor={nav2}
                    ref={(slider) => (slider1 = slider)}
                    speed={500}
                    fade={true}
                    afterChange={(index) => {
                      handleInventoryItem(index);
                    }}
                  >
                    {image.split(",").map((imageGD, i) => (
                      <div key={i}>
                        <div
                          style={{ position: "relative" }}
                          className="magnifier-desktop"
                        >
                          <Magnifier
                            className="image-1x"
                            src={getImageFromDrive(imageGD)}
                            zoomImgSrc={getImageFromDrive(imageGD)}
                            zoomFactor={1}
                            mgWidth={350}
                            mgHeight={350}
                            mgBorderWidth={8}
                          />
                          <Magnifier
                            className="image-2x"
                            src={getImageFromDrive(imageGD)}
                            zoomImgSrc={getImageFromDrive(imageGD)}
                            zoomFactor={1}
                            mgWidth={350}
                            mgHeight={350}
                            mgBorderWidth={8}
                          />
                        </div>
                        <div
                          style={{ position: "relative" }}
                          className="magnifier-mobile"
                        >
                          <div className="magnifier">
                            {getImageFromDrive(imageGD) && (
                              <img
                                src={getImageFromDrive(imageGD)}
                                width="100%"
                                height="auto"
                                onClick={() => openFullScreen(imageGD)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  {image.split(",").length !== 1 && (
                    <>
                      <div className="btns-slideshow">
                        <button
                          onClick={activeLineSlide}
                          className={slideState ? "" : "active-slide"}
                        >
                          <img src={iconSlideShow} alt="Icon Slideshow" />
                        </button>
                        <button
                          onClick={activeGridSlide}
                          className={slideState ? "active-slide" : ""}
                        >
                          <img src={iconGrid} alt="Icon Grid" />
                        </button>
                      </div>
                      <Slider
                        className="content-product__multi-image selector-slide"
                        asNavFor={nav1}
                        ref={(slider) => (slider2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={slideState ? false : true}
                        speed={500}
                        centerMode={true}
                        centerPadding={0}
                      >
                        {image.split(",").map((imageGD, i) => (
                          <div key={i}>
                            <img
                              className="image-1x"
                              src={getImageFromDrive(imageGD)}
                              alt=""
                            />
                            <img
                              className="image-2x"
                              src={getImageFromDrive(imageGD)}
                              alt=""
                            />
                          </div>
                        ))}
                      </Slider>
                    </>
                  )}
                </div>
              )}

              <div className="col-lg-5">
                <div className="product-detail">
                  <div className="product-detail__title hidde-mobil">
                    <h3 className="u-text-center">{articlenumber}</h3>
                    <h2 className="product-detail__fabric-name">
                      {fabricname}
                    </h2>
                    <div className="content-like-heart">
                      <input
                        type="checkbox"
                        id={itemToCart.id}
                        checked={favoriteList?.favorites?.data[0]?.attributes?.favoritelist.some(
                          (fav) => fav.productsid === articlenumber
                        )}
                        onChange={() => hadnleAddFavorite(itemToCart)}
                        className="element-checkbox like-check"
                      />
                      <label htmlFor={itemToCart.id}>
                        <HeartLike />
                      </label>
                    </div>
                  </div>
                  {(colornumber?.split(",").length === 1 &&
                    colornumber?.split(",")[0] === "0") ||
                  !colornumber?.split(",") ? (
                    ""
                  ) : (
                    <div className="product-detail__toolbar-color hidde-mobil">
                      <span>Color :</span>
                      <Select
                        name=""
                        id="colorOptions"
                        onChange={() => handleChangeColor()}
                      >
                        {colornumber?.split(",").map((colors, i) => (
                          <option value={i} key={i}>
                            {colors}
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}

                  <div className={`product-detail__cards`}>
                    {authUser.typeCustomer === "Regular customer" && (
                      <div className="product-detail__card-length-order">
                        <h4>Length Order</h4>
                        <List>
                          <Quantity>
                            <div className="quanty-box">
                              <span>Qty (mts):</span>
                              <span>
                                <button
                                  className="btn-quantity show-btns-quantity"
                                  onClick={leesQty}
                                >
                                  <p className="btn-quantity-minus">-</p>
                                </button>
                                <InputQuantity
                                  // defaultValue={valueInputPrice}
                                  onChange={(e) => changeValuePrice(e)}
                                  onKeyUp={(e) => changeValuePrice(e)}
                                  onInput={(e) => validateInputNumber(e)}
                                  className="input-cuantity input-stock"
                                  disabled={inventoryItem === 0}
                                  type="number"
                                  min="1"
                                  max={inventoryItem}
                                  value={valueQuantity}
                                />
                                <button
                                  className="btn-quantity show-btns-quantity"
                                  onClick={(e) => addMoreQty(e)}
                                >
                                  +
                                </button>
                              </span>
                            </div>
                          </Quantity>
                          <TotalPrice>
                            <span className="label-price">
                              Total Price (M):
                            </span>
                            <span className="price-cost">
                              ${quatyTotal.toFixed(2)}
                            </span>
                          </TotalPrice>
                        </List>

                        <button
                          className="u-margin-tb-2 u-margin-top-2 btn btn-primary btn-large"
                          onClick={() => addToCarttest(itemToCart, "product")}
                          // disabled={stockAvailable || inventoryItem === 0}
                        >
                          Add to Cart
                        </button>
                        <div className="product-detail__footer">
                          {/* <span>$ {plpProduct[0]?.attributes.regularprice.toFixed(2)} / mts</span> */}
                          <p>
                            {valueQuantity < 500 ? (
                              <b>
                                {" "}
                                $
                                {parseFloat(
                                  plpProduct[0]?.attributes.regularprice
                                ).toFixed(2)}
                              </b>
                            ) : (
                              <b> ${parseFloat(priceb).toFixed(2)}</b>
                            )}
                            / mts
                          </p>
                          <>
                            {inventoryItem === 0 ? (
                              <span className="u-text-small u-text-danger">
                                No stock available
                              </span>
                            ) : (
                              <>
                                <p>
                                  Stock:{" "}
                                  <b> {parseFloat(inventoryItem).toFixed(2)}</b>{" "}
                                  / mts
                                </p>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    )}

                    {authUser?.typeCustomer === "Regular customer" ? (
                      <div className="product-detail__card-length-order ml10">
                        <h4 className="color-text-gray">Swatch Order</h4>
                        <List>
                          <Quantity>
                            <div className="quanty-box">
                              <span>Qty:</span>
                              <span>
                                <button
                                  className="btn-quantity show-btns-quantity btn-swatch"
                                  onClick={leesQtySwatch}
                                >
                                  <p className="btn-quantity-minus">-</p>
                                </button>
                                <InputQuantity
                                  onChange={(e) => changePriceSwatch(e)}
                                  className="input-cuantity input-stock-swatch"
                                  type="text"
                                  value={valueQuantitySwatch}
                                />
                                <button
                                  className="btn-quantity show-btns-quantity btn-swatch"
                                  onClick={addMoreQtySwatch}
                                >
                                  +
                                </button>
                              </span>
                            </div>
                          </Quantity>
                          <TotalPrice>
                            <span className="label-price">Total Price:</span>
                            <span className="price-cost">
                              {(
                                valueQuantitySwatch *
                                plpProduct[0]?.attributes.regularprice
                              ).toFixed(2)}
                            </span>
                          </TotalPrice>
                        </List>

                        <button
                          disabled={updateLoading}
                          onClick={() =>
                            handleAddLengthToCart(plpProduct[0], "length_order")
                          }
                          className="u-margin-tb-2 u-margin-bottom-2 btn btn-primary btn-large btn-swatch"
                        >
                          {updateLoading ? <SmallLoader /> : "Add to cart"}
                        </button>

                        <div className="product-detail__footer">
                          <span>
                            ${" "}
                            {plpProduct[0]?.attributes.regularprice.toFixed(2)}{" "}
                            / mts
                          </span>
                          <p>$0.00 / mts</p>
                          <span>
                            <p>
                              Stock: <b>50</b> / mts
                            </p>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="u-text-center">
                        <ButtonTL
                          color="primary"
                          weight="light"
                          size="small"
                          className="u-margin-top-1"
                          onClick={() =>
                            handleAddLengthToCart(plpProduct[0], "swatch")
                          }
                        >
                          {updateLoading ? "" : "Swatch Request"}
                        </ButtonTL>
                      </div>
                    )}
                  </div>
                </div>

                <ListDetail>
                  <li>
                    <p>
                      {plpProduct[0]?.attributes?.productdetail
                        ?.split("\n")
                        .map((pre, i) => (
                          <span
                            className="description-font"
                            key={i}
                            dangerouslySetInnerHTML={{
                              __html: `<div>${pre}</div>`,
                            }}
                          ></span>
                        ))}
                    </p>
                  </li>
                </ListDetail>
                {dataIcons.map((im, i) => (
                  <img
                    key={i}
                    src={im.image}
                    alt={im.name}
                    className="icon-placement"
                  />
                ))}
                <div className="video-content">
                  {plpProduct[0]?.attributes?.video.startsWith("https") && (
                    <>
                      {plpProduct[0]?.attributes && (
                        <video controls muted>
                          <source
                            src={getVideoFromDrive(
                              plpProduct[0]?.attributes?.video
                            )}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </>
                  )}
                </div>
                {subimages && (
                  <div className="subimages-content">
                    {subimages.map((image, i) => (
                      <div key={i}>
                        {(image !== "" || image !== undefined || image) && (
                          <>
                            {getImageFromDrive(image) !== null && (
                              <img
                                src={getImageFromDrive(image)}
                                alt=""
                                onClick={(e) => openFullScreen(image)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                <ListDetail>
                  <li>
                    <div className="tags-keywords">
                      {searchword?.split(",").map((keyword, i) => (
                        <div className="u-margin-bottom-05" key={i}>
                          {keyword.trimStart() === "" ||
                          keyword.trimStart() === 0 ||
                          keyword.trimStart() === "0" ? (
                            ""
                          ) : (
                            <Link to={`/products/?q=${keyword}`}>
                              {keyword}
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  </li>
                </ListDetail>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            style={customStylesMagnifier}
            className="modal-magnifierResponsive"
          >
            <div className="modal-close">
              <img src={closeImg} onClick={closeModal} />
            </div>
            <div className="modal-image">
              <Magnifier
                className="image-1x"
                src={getImageFromDrive(modalImage)}
                zoomImgSrc={getImageFromDrive(modalImage)}
                zoomFactor={1}
                mgWidth={270}
                mgHeight={270}
                mgBorderWidth={8}
              />
              <Magnifier
                className="image-2x"
                src={getImageFromDrive(modalImage)}
                zoomImgSrc={getImageFromDrive(modalImage)}
                zoomFactor={1}
                mgWidth={270}
                mgHeight={270}
                mgBorderWidth={8}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default DetailProduct;
