import gql from "graphql-tag";

export const NEW_USER = gql`
  mutation createUser($data: UsersPermissionsUserInput!) {
    createUsersPermissionsUser(data: $data) {
      data {
        id
        attributes {
          createdAt
        }
      }
    }
  }
`;

/**
 * UPDATE USER => Mutation for update user
 */

export const EDIT_USER = gql`
  mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        attributes {
          username
        }
      }
    }
  }
`;

export const AUTH_USER = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        username
        email
        id
        confirmed
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($login: String!, $password: String!) {
    login(input: { identifier: $login, password: $password }) {
      jwt
      user {
        id
        username
        email
        role {
          id
        }
      }
    }
  }
`;
/**
 * CREATE_ADDRESS => Mutation for create user
 */
export const CREATE_ADDRESS = gql`
  mutation createAddres($data: AddressInput!) {
    createAddress(data: $data) {
      data {
        attributes {
          createdAt
        }
      }
    }
  }
`;
/**
 * EDIT_ADDRESS => Mutation to edit address based on id
 */
export const EDIT_ADDRESS = gql`
  mutation updateAddress($id: ID!, $data: AddressInput!) {
    updateAddress(id: $id, data: $data) {
      data {
        attributes {
          users_permissions_user {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * ADD_FAVORITE => Mutation to create a new
 */

export const ADD_FAVORITE = gql`
  mutation createFavorite($data: FavoriteInput!) {
    createFavorite(data: $data) {
      data {
        attributes {
          productsid
        }
      }
    }
  }
`;

export const UPDATE_FAVORITE = gql`
  mutation updateFavorite($id: ID!, $data: FavoriteInput!) {
    updateFavorite(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

/**
 * DELETE_FAVORITE => Delete a favorite according to the id
 */
export const DELETE_FAVORITE = gql`
  mutation deleteFavorite($id: ID!) {
    deleteFavorite(id: $id) {
      data {
        id
      }
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(id: $id) {
      data {
        id
      }
    }
  }
`;

/**
 * ADD TO ORDER => Add order user
 */

export const ADD_ORDER = gql`
  mutation createOrder($data: OrderhistoryInput!) {
    createOrderhistory(data: $data) {
      data {
        attributes {
          id_payment
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $code: String!) {
    resetPassword(
      password: $password
      code: $code
      passwordConfirmation: $password
    ) {
      user {
        id
      }
    }
  }
`;
export const ContactUsRegister = gql`
  mutation createContactUs($input: createContactUsFormInput) {
    createContactUsForm(input: $input) {
      contactUsForm {
        FullName
        Email
        Description
      }
    }
  }
`;

export const ModifyStatusOrder = gql`
  mutation updateOrderhistory($order_id: updateOrderhistoryInput) {
    updateOrderhistory(input: $order_id) {
      orderhistory {
        status
      }
    }
  }
`;
export const AddFedexOrder = gql`
  mutation addFedexOrder($input: createFedexorderInput) {
    createFedexorder(input: $input) {
      fedexorder {
        orderhistory {
          id
        }
        dataFedex
        transaction_id
        category
        service_type
        ship_date
        Shipping_pdf
        Commercial_invoice_pdf
      }
    }
  }
`;

export const ADD_CHARGE = gql`
  mutation updateOrderhistory($id: ID!, $input: editOrderhistoryInput) {
    updateOrderhistory(input: { where: { id: $id }, data: $input }) {
      orderhistory {
        chargetocard {
          articlenumber
          colornumber
          quantity
          amount
          idcharge
        }
      }
    }
  }
`;

export const CREATE_SHIPPING_CART = gql`
  mutation createShoppingCart($data: CartItemInput!) {
    createCartItem(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_SHIPPING_CART = gql`
  mutation updateShoppingCart($id: ID!, $data: CartItemInput!) {
    updateCartItem(id: $id, data: $data) {
      data {
        attributes {
          users_permissions_user {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SHIPPING_CART = gql`
  mutation deleteShippingCart($id: ID!) {
    deleteCartItem(id: $id) {
      data {
        id
      }
    }
  }
`;

export const GET_SHIPPING_CART = gql`
  query getCartItems($id: ID!) {
    cartItems(filters: { users_permissions_user: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          cartitem {
            productId
            colortype
            price
            quantity
            productSummery
            image
            supplierid
            supplierproductname
            unitprice
            typeofpurchase
          }
        }
      }
    }
  }
`;

export const GET_CART_ITEM = gql`
  query getCartItem($id: ID!) {
    cartItem(id: $id) {
      data {
        id
        attributes {
          cartitem {
            productId
            colortype
            price
            quantity
            productSummery
            image
            supplierid
            supplierproductname
            unitprice
            typeofpurchase
          }
        }
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrderhistory($id: ID!, $data: OrderhistoryInput!) {
    updateOrderhistory(id: $id, data: $data) {
      data {
        id
        attributes {
          status
          amount
          email
          username
          shippingFee
          companyName
          dateOrder
          payment_method
          shippingAddress {
            address
            city
            shippingUser
            phoneNumber
            zipcode
            street
          }
          detailProducts {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          detailSwatch {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          status          
        }
      }
    }
  }
`;
