import React, { useState, useEffect, useContext } from "react";

import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";

import { customStyles } from "../services/modal";
import { BiPurchaseTag } from "react-icons/bi";

import { USER_DATA, ORDER_HISTORY } from "../querys";
import {
  Row,
  TextAddress,
  CardAddress,
  ButtonChange,
} from "../components/tabs/components/fields";
import Modal from "react-modal";

// import "" from "../images/icons/no-image.svg";

import { getImageFromDrive } from "../services/api";
import { AuthUserContext } from "../context/AuthUser";
import { Link } from "gatsby";
const Text = styled.h5`
  font-size: 1.3em;
  text-align: center;
  color: var(--color-dark);
  margin: 1em 0;
`;

const OrderHistoryInfo = ({ current_user }) => {
  const { authUser } = useContext(AuthUserContext);
  Modal.setAppElement("#___gatsby");
  /**
   * Validation if user exist
   */
  const [userName, setUserName] = useState("");
  const { data: userDataas } = useQuery(USER_DATA, {
    variables: { id: parseInt(authUser?.user?.id) },
    onCompleted: (data) => {
      setUserName(
        data.usersPermissionsUser.data.attributes.firstName +
          " " +
          data.usersPermissionsUser.data.attributes.lastName
      );
    },
  });
  // const { firstName, lastName } =
  //   userDataas?.usersPermissionsUser?.data?.attributes;
  const [dataOrders, setDataOrders] = useState([]);
  const { refetch, data } = useQuery(ORDER_HISTORY, {
    variables: { id: current_user.id },
    onCompleted: (data) => {
      setDataOrders(data.orderhistories.data);
    },
  });
  // console.log(dataOrders, "dataOrdersdataOrders");
  const [modalDetail, setModalDetail] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({
    email: "",
    amount: 0,
    address: "",
    city: "",
    state: "",
    companyName: "",
    created_at: "",
    payment_method: "",
    order_state: {
      Status: "",
    },
    detailProducts: [],
    detailSwatch: [],
  });
  const openModalProducts = (product) => {
    setCurrentOrder(product);
    setModalDetail(true);
  };

  useEffect(() => {
    refetch();
  }, []);
  return (
    <div className="container">
      <div className="container-fluid">
        {/* <Text className="text-welcome">Welcome / {userName}</Text> */}
        <h2 className="title-orders-page">Order history</h2>
        <div className="container">
          {dataOrders?.length === 0 && (
            <div className="empty-data-order">
              <BiPurchaseTag />
              <h4 className="u-text-center">You don't have orders yet</h4>
            </div>
          )}
          <div className="row">
            {dataOrders?.map((order, i) => (
              <div className="col-lg-12" key={i}>
                <div className="container">
                  <Row className="row border-top">
                    <CardAddress className="col-lg-8 col-md-8">
                      <TextAddress>
                        <b>Order #:</b> {order.id}
                      </TextAddress>
                      {/* <TextAddress>
                        <b>Company Name:</b> {order.attributes.companyName}
                      </TextAddress> */}
                      {/* <TextAddress>
                        <b>Amount:</b> $ {order.attributes.amount?.toFixed(2)}
                      </TextAddress> */}
                      <TextAddress>
                        <b>Order Date:</b>
                        <span style={{ margin: "0 0.25em" }}>
                          {new Date(
                            order.attributes.dateOrder
                          ).toLocaleDateString()}
                        </span>
                        <span style={{ margin: "0 0.25em" }}>
                          {new Date(
                            order.attributes.dateOrder
                          ).toLocaleTimeString()}
                        </span>
                      </TextAddress>
                      {/* <TextAddress className="u-text-capitalize">
                        <b>Payment Method:</b> {order.attributes.payment_method}
                      </TextAddress> */}

                      <TextAddress className="u-text-capitalize">
                        <b>Delivery Status #:</b> {order.attributes.status}{" "}
                      </TextAddress>
                      {order.attributes.state && (
                        <TextAddress>
                          <b>State: </b>{" "}
                          <span
                            className={`badge-state ${
                              order.attributes.status === "Pending"
                                ? "badge-pending"
                                : "badge-success"
                            }`}
                          >
                            {order.attributes.status}
                          </span>
                        </TextAddress>
                      )}
                    </CardAddress>
                    <ButtonChange className="col-lg-4 col-md-4">
                      <Link
                        to={`/order-history/${order.id}`}
                        className="btn btn-danger u-size-btn"
                      >
                        View Details
                      </Link>
                    </ButtonChange>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal isOpen={modalDetail} style={customStyles}>
        <div className="container">
          <div className="head-modal">
            <p>Order detail</p>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <TextAddress>
                <b>Company Name:</b> {currentOrder.companyName}
              </TextAddress>
              <TextAddress>
                <b>Amount:</b> $ {currentOrder.amount?.toFixed(2)}
              </TextAddress>
              <TextAddress>
                <b>Order Date:</b>
                {/* {currentOrder.dateOrder} */}

                <span style={{ margin: "0 0.25em" }}>
                  {new Date(currentOrder.dateOrder).toLocaleDateString()}
                </span>
                <span style={{ margin: "0 0.25em" }}>
                  {new Date(currentOrder.dateOrder).toLocaleTimeString()}
                </span>
              </TextAddress>
              <TextAddress className="u-text-capitalize">
                <b>Payment Method:</b> {currentOrder.payment_method}
              </TextAddress>
              {currentOrder.order_state && (
                <TextAddress>
                  <b>State: </b>{" "}
                  <span
                    className={`badge-state ${
                      currentOrder.order_state.Status === "Pending"
                        ? "badge-pending"
                        : "badge-success"
                    }`}
                  >
                    {currentOrder.order_state.Status}
                  </span>
                </TextAddress>
              )}
            </div>
            {currentOrder.detailProducts && (
              <>
                {currentOrder.detailProducts.length > 0 && (
                  <div className="content-scroll-h">
                    <h2 className="title-length-order">Length Order</h2>
                    <table className="col-lg-12 col-md-12 table-order-content">
                      <thead>
                        <tr>
                          <th>Article Number</th>
                          <th>Color Number</th>
                          <th>Image</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentOrder.detailProducts.map((product, i) => (
                          <tr key={i}>
                            <td>{product.articleNumber}</td>
                            <td>{product.colorNumber.replaceAll('"', "")}</td>
                            <td>
                              {product.image === "#N/A" ? (
                                // <img
                                //   className="image-default-small"
                                //   src={noImage}
                                //   width={"25px"}
                                //   alt=""
                                // />
                                ""
                              ) : (
                                <img
                                  src={getImageFromDrive(product.image)}
                                  alt=""
                                  width={"25px"}
                                />
                              )}
                            </td>
                            <td>{product.quantity}</td>
                            <td>${product?.totalPrice?.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
            {currentOrder.detailSwatch && (
              <>
                {currentOrder.detailSwatch.length > 0 && (
                  <div className="content-scroll-h">
                    <h2 className="title-swatch-order">Swatch Order</h2>
                    <table className="col-lg-12 col-md-12 table-order-content">
                      <thead>
                        <tr>
                          <th>Article Number</th>
                          <th>Color Number</th>
                          <th>Image</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentOrder.detailSwatch.map((product, i) => (
                          <tr key={i}>
                            <td>{product.articleNumber}</td>
                            <td>{product.colorNumber.replaceAll('"', "")}</td>
                            <td>
                              {product.image === "#N/A" ? (
                                ""
                              ) : (
                                // <img
                                //   className="image-default-small"
                                //   src={noImage}
                                //   width={"25px"}
                                //   alt=""
                                // />
                                <img
                                  src={getImageFromDrive(product.image)}
                                  alt=""
                                  width={"25px"}
                                />
                              )}
                            </td>
                            <td>{product.quantity}</td>
                            <td>${product?.totalPrice?.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
            <div className="u-margin-auto">
              <button
                className="btn btn-primary u-size-150"
                onClick={() => setModalDetail((modalDetail) => !modalDetail)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderHistoryInfo;
