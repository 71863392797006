import React from "react";

import { Container } from "../components/styled/ProductsStyled";
import DataTable from "react-data-table-component";

import { customStylesTable } from "../services/modal";

import { getAllOrders } from "../querys";
import { useQuery } from "@apollo/client";
import { Link } from "gatsby";

const AllOrders = () => {
  const { data: allOrders } = useQuery(getAllOrders, {
    onCompleted: () => {},
  });

  const columns = [
    {
      name: "Order #",
      maxWidth: "150px",
      center: true,
      selector: (row) => row.id,
    },
    {
      name: "Amount",
      center: true,
      selector: (row) => row?.amount,
    },
    {
      name: "Email",
      center: true,
      selector: (row) => row?.email,
    },
    {
      name: "Payment Metod",

      center: true,
      selector: (row) => row?.payment_method,
    },
    {
      name: "Status",

      center: true,
      selector: (row) => row?.status,
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <div>
          <Link to={"/orders-history/" + row.id} style={{ color: "white", background: "black", padding: "5px 15px" }}>
            Edit
          </Link>
          {/* <button>Set Status</button> */}
        </div>
      ),
    },
  ];

  return (
    <>
      <Container className="container">
        <div className="">
          <div className="table_style">
            <DataTable
              title="Order History"
              columns={columns}
              data={allOrders?.orderhistories?.data.map((item) => ({
                id: item.id,
                ...item.attributes,
              }))}
              customStyles={customStylesTable}
              pagination
              paginationPerPage={50}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default AllOrders;
