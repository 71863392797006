import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import DataTable from "react-data-table-component";

import { Container } from "../../components/styled/ProductsStyled";

import { getAllOrders, getFedexOrder } from "../../querys";
import { useMutation, useQuery } from "@apollo/client";
import ShippingInformation from "./components/ShippingInformation";
import Modal from "react-modal";
import { customStyles } from "../../services/modal";
import { customStylesTable } from "../../services/modal";
import { toast } from "react-toastify";
import { ModifyStatusOrder } from "../../mutations";
import axios from "axios";
import { apiBase } from "../../services/appenv";
import { InputField } from "../../components/tabs/components/fields";

const OrderPage = () => {
  // useEffect(() => {
  //   const tok = localStorage.getItem("user_data")
  //   if (!tok) {
  //     navigate("/home")
  //   }
  // })
  const token = localStorage.getItem("token");
  const HEADERS = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const dashicon = require("../../images/icons/dash_icon.svg");

  const [aux, setAux] = useState();
  //const tdata = useOrders();
  const { data: tdata } = useQuery(getAllOrders, {
    onCompleted: () => {
      setAux(true);
    },
  });

  const [csub_menu, setCSubMenu] = useState(false);
  const handleClickCustomer = (e) => {
    if (csub_menu === false) {
      e.target.closest("div").classList.add("item_selected");
      setCSubMenu(true);
    } else {
      e.target.closest("div").classList.remove("item_selected");
      setCSubMenu(false);
    }
  };

  const [isub_menu, setISubMenu] = useState(false);
  const handleClickItems = (e) => {
    if (isub_menu === false) {
      e.target.closest("div").classList.add("item_selected");
      setISubMenu(true);
    } else {
      e.target.closest("div").classList.remove("item_selected");
      setISubMenu(false);
    }
  };

  const [fedexData, setFedexData] = useState();
  const [order_id, setOrderId] = useState(0);
  const handlerFedexOrderData = (e, order_id) => {
    setOrderId(order_id);
    refetch();
  };

  const { data: fedexOrder, refetch } = useQuery(getFedexOrder, {
    variables: {
      order_id: order_id,
    },
    onCompleted: () => {
      setFedexData(fedexOrder);
      if (fedexOrder.fedexorders.data.length !== 0) {
        // console.log(fedexOrder.fedexorders.data.length, 'fedexOrderfedexOrder2222')
        setModalTransaction(true);
        setOrderId(0);
      }
    },
  });  
  const [modalTransaction, setModalTransaction] = useState(false);
  const closeModalTransaction = () => {
    setModalTransaction(false);
  };

  const [modalOrderStatus, setModalOrderStatus] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderid, setOrderid] = useState("");
  const [changeTextMail, setChangeTextMail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [fedexNumber, setFedexNumber] = useState("");
  const handleChangeStatus = (e, status, id, email) => {
    const openFloat = document.querySelectorAll(".item_selected");
    if (openFloat) {
      openFloat.forEach((op) => {
        op.classList.remove("item_selected");
      });
      setCSubMenu(false);
      setISubMenu(false);
    }
    setModalOrderStatus(true);
    setOrderStatus(status);
    setOrderid(id);
    setUserEmail(email);
    if (status === "Canceled") {
      setChangeTextMail(
        "Dear custumer,\n\nYour order has been canceled because:\n"
      );
    } else {
      setChangeTextMail("");
    }
  };
  const closeOrderModal = () => {
    setModalOrderStatus(false);
  };
  const handleChangeOrder = (event) => {
    const { value } = event.target;
    setOrderStatus(value);
    if (value === "Canceled") {
      setChangeTextMail(
        "Dear custumer,\n\nYour order has been canceled because:\n"
      );
    } else {
      setChangeTextMail("");
    }
  };
  const handleChangeMail = (event) => {
    const { value } = event.target;
    setChangeTextMail(value);
  };
  const handleChangeFedex = (event) => {
    const { value } = event.target;
    setFedexNumber(value);
  };
  const [updateStatus] = useMutation(ModifyStatusOrder, {
    onCompleted: () => {
      window.location.reload();
    },
  });
  const submitStatus = (e) => {
    e.preventDefault();
    if (orderStatus === "Ordered" || orderStatus === "Sent") {
      changeStatus();
    } else {
      let mailData = {
        Email: userEmail,
        Mail: changeTextMail.replace(/\n/g, "<br/>"),
        Status: orderStatus,
        OrderId: orderid,
        FedexNumber: fedexNumber,
      };
      axios
        .post(
          `${apiBase}/orderhistories/statusmail`,
          JSON.stringify(mailData),
          HEADERS
        )
        .then((response) => {
          toast.success(`Email send successfully!`);
          navigate("/orders");
        })
        .catch((error) => {
          toast.error(`Error, please try again`);
        })
        .finally(() => {
          changeStatus();
        });
    }
  };

  const changeStatus = () => {
    updateStatus({
      variables: {
        order_id: {
          where: {
            id: orderid,
          },
          data: {
            status: orderStatus,
          },
        },
      },
    });
  };

  const columns = [
    {
      name: "Order #",
      maxWidth: "60px",
      center: true,
      selector: (row) => row.id,
    },
    {
      name: "Date Order",
      maxWidth: "115px",
      center: true,
      selector: (row) => row?.DateOrder?.split(" ")[0]?.split(",")[0],
    },
    {
      name: "Customer",
      maxWidth: "220px",
      center: true,
      cell: (row) => (
        <div className="customer_info_head">
          <p>
            {row?.companyName}{" "}
            <span className="customer_down" onClick={handleClickCustomer}>
              ▼
            </span>
          </p>
          <div className="customer_info">
            <p className="customer_info_user">
              {!row?.shippingAddress ? " " : row?.shippingAddress?.shippingUser}
            </p>
            <p className="customer_info_email">{row?.email}</p>
            <p className="customer_info_phone">{row?.phone}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Items",
      center: true,
      maxWidth: "115px",
      cell: (row) => (
        <div className="items_data_head">
          <p>
            {row?.detailProducts?.length +
              row?.detailSwatch?.length +
              " item(s) "}
            <span className="items_down" onClick={handleClickItems}>
              ▼
            </span>
          </p>
          <div className="items_data">
            <p className="items_data_content">
              {row?.detailSwatch?.length === 0 &&
              row?.detailProducts?.length === 0
                ? "No data"
                : row?.detailProducts?.length === 0
                ? ""
                : row?.detailProducts?.map((itemp, key) => (
                    <span key={key + "p"}>{itemp?.articleNumber}</span>
                  ))}
              {row?.detailSwatch?.length === 0 &&
              row?.detailProducts?.length === 0
                ? ""
                : row?.detailSwatch?.length === 0
                ? ""
                : row?.detailSwatch?.map((items, key) => (
                    <span key={key + "s"}>{items.articleNumber}</span>
                  ))}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Payment Status",
      center: true,
      maxWidth: "145px",
      cell: (row) => (
        <div className="payment_status">
          <p>Paid</p>
        </div>
      ),
    },
    {
      name: "Fulfillment Status",
      maxWidth: "160px",
      cell: (row) => (
        <div
          className={row.status === "Sent" ? "status_sent" : "status_ordered"}
        >
          {row.status === "Sent" ? <p>Fulfielled</p> : <p>Unfulfilled</p>}
        </div>
      ),
      center: true,
    },
    {
      name: "Status",
      center: true,
      maxWidth: "130px",
      cell: (row) => (
        <div className="change_status">
          <a
            onClick={(e) =>
              handleChangeStatus(e, row.status, row.id, row.email)
            }
          >
            {row.status}
          </a>
        </div>
      ),
    },
    {
      name: "Deliver Method",
      center: true,
      cell: (row) => (
        <div className="table-buttons">
       
          {row.status === "Sent" ? (
            <p
              className="table-button-filled"
              onClick={(e) => handlerFedexOrderData(e, row.id)}
            >
              <img src={dashicon} alt="dashicon" />
            </p>
          ) : (
            <a href={"/orders/" + row.id} className="table-button-notfilled">
              Fedex
            </a>
          )}
          {/*<a className="table-button-d" disable="true">
            UPS
          </a>
          <a className="table-button-d" disable="true">
            DHL
          </a>*/}
        </div>
      ),
    },
  ];
  // console.log(tdata, 'tdata?.orderhistories')
  return (
    <>
      <Container className="container">
        <div className="">
          {aux ? (
            <div className="table_style">
              <DataTable
                title="List orders"
                columns={columns}
                data={tdata?.orderhistories?.data.map((item) => ({
                  id: item.id,
                  ...item.attributes,
                }))}
                customStyles={customStylesTable}
                pagination
              />
            </div>
          ) : (
            <p className="no-data-order">No Data</p>
          )}
        </div>
        <Modal isOpen={modalTransaction} style={customStyles}>
          <div className="head-modal">
            <p>Shipment information</p>
            <button onClick={closeModalTransaction}>X</button>
          </div>
          <div className="content-card-radio">
            <ShippingInformation information={fedexData} />
          </div>
          <div className="u-margin-top-1">
            <button
              className="u-margin-auto btn btn-danger u-size-150"
              onClick={closeModalTransaction}
            >
              OK
            </button>
          </div>
        </Modal>
        <Modal isOpen={modalOrderStatus} style={customStyles}>
          <div className="head-modal">
            <p>Change Order Status</p>
            <button onClick={closeOrderModal}>X</button>
          </div>
          <div>
            <form className="modal-order-status">
              <label>Status</label>
              <select
                defaultValue={orderStatus}
                name=""
                id="selector-order"
                onChange={(e) => handleChangeOrder(e)}
              >
                <option value="Ordered">Ordered</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Canceled">Canceled</option>
              </select>
              {(orderStatus === "Shipped" || orderStatus === "Delivered") && (
                <div>
                  <label>Fedex tracking number</label>
                  <InputField>
                    <input
                      type="text"
                      id="fedexNumber"
                      className="input-m1020"
                      onChange={(e) => handleChangeFedex(e)}
                      value={fedexNumber}
                      placeholder="If more than one separate with comas."
                    />
                  </InputField>
                </div>
              )}
              {orderStatus === "Canceled" && (
                <div>
                  <label>Mail</label>
                  <textarea
                    id="mail"
                    cols="30"
                    rows="10"
                    onChange={(e) => handleChangeMail(e)}
                    value={changeTextMail}
                  ></textarea>
                </div>
              )}
              <div className="u-text-center u-display-flex u-margin-top-2 u-justify-center">
                <button
                  className="btn btn-danger btn-width-200 u-margin-right-1 u-margin-left-1"
                  onClick={closeOrderModal}
                >
                  Cancel
                </button>
                {orderStatus !== "Ordered" && orderStatus !== "Sent" && (
                  <button
                    className="btn btn-primary btn-width-200 u-margin-right-1 u-margin-left-1"
                    onClick={(e) => submitStatus(e)}
                  >
                    Apply & Email
                  </button>
                )}
                <button
                  className="btn btn-secondary btn-width-200 u-margin-right-1 u-margin-left-1"
                  onClick={changeStatus}
                >
                  Apply & NOT Email
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default OrderPage;
